import React from "react";

import ArchitectureIcon from "../images/service-icons/interior-design.svg";
import RenovationIcon from "../images/service-icons/building-renovation.svg";
import ConstructionIcon from "../images/service-icons/construction.svg";
import Eyebrow from "./eyebrow";
import ServiceItem from "./serviceItem";

const Services = () => {
  return (
    <div id="#services">
      <div className="container mx-auto">
        <div className="flex flex-col md:gap-20 gap-10 lg:py-28 md:py-20 py-12">
          <div className="grid lg:grid-cols-12 grid-cols-1 gap-8">
            <div className="lg:col-span-8">
              <Eyebrow label="DỊCH VỤ CỦA MỘC HÀ" />
              <h2 className="font-display md:text-display-xl text-display-md pt-5">
                XƯỚNG GỖ MỘC HÀ CUNG CẤP DỊCH VỤ CẤP CAO NHẤT
              </h2>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col gap-8">
            <ServiceItem
              icon={ArchitectureIcon}
              title="Thiết kế kiến trúc và nội thất"
              description="Thiết kế kiến trúc chuyên nghiệp, bao gồm thiết kế kiến trúc nhà phố, biệt thự, các không gian thương mại như văn phòng, nhà hàng, và khách sạn."
            />
            <ServiceItem
              icon={RenovationIcon}
              title="Đóng đỗ gỗ theo yêu cầu"
              description="Chúng tôi nhận đóng các sản phẩm đồ gỗ nội thất như: đóng giường ngủ, đóng tủ quần áo, đóng tủ bếp, kệ tivi, tủ cầu thang, tủ giày, giá sách, tủ trang trí, tủ rượu…."
            />
            <ServiceItem
              icon={ConstructionIcon}
              title="Tư vấn công trình nội thất"
              description="Dịch vụ tư vấn công trình nội thất trọn gói gồm đủ các khâu từ lập kế hoạch, thiết kế đến thi công, giúp khách hàng tiết kiệm thời gian và chi phí, đảm bảo dự án hoàn thành đúng tiến độ."
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Services;

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Eyebrow from "./eyebrow";
import AwardBadge from "../images/award-badge.svg";

const About = () => {
  const data = useStaticQuery(graphql`
    {
      aboutimage: file(relativePath: { eq: "about-creative.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 592
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <div id="#about">
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-20 lg:py-32 py-12 items-center">
          <div className="lg:col-span-6 flex flex-col gap-6">
            <Eyebrow label="GIỚI THIỆU" />
            <h2 className="font-display md:text-display-xl text-display-md font-normal pb-4">
              Xưởng gỗ Mộc Hà nhận đóng &amp; thi công đồ gỗ tại Hải Phòng
            </h2>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              Nội thất Mộc Hà hướng đến việc tạo ra các công trình nội thất đẳng cấp, vượt giới hạn của thời gian, khơi dậy sự đổi mới và tôn vinh nét đẹp trong từng góc cạnh của cuộc sống.
            </p>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
            Chúng tôi không ngừng nỗ lực để khơi dậy cảm hứng sáng tạo từ những ý tưởng độc đáo, mang đến cho khách hàng những trải nghiệm thẩm mỹ đỉnh cao. Mỗi dự án là một hành trình khám phá vẻ đẹp hoàn mỹ, nơi từng chi tiết nhỏ đều được chăm chút kỹ lưỡng để tạo nên một tổng thể hài hòa, nâng tầm giá trị không gian sống và tôn vinh phong cách sống tinh tế của bạn.
            </p>
          </div>
          <div className="lg:col-span-6 flex flex-col gap-8 relative">
            <GatsbyImage
              image={getImage(data.aboutimage)}
              alt="Interior Design"
            />
            <img
              src={AwardBadge}
              alt="Award Badge"
              className="absolute left-[42%] -top-14"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
